import { isString, joinClassNames } from "@common/lib/util"
import Svg from "@common/svg"
import Checkbox from "@common/v2/checkbox"
import { ReactNode } from "react"

export default function DropDownOption({
  icon,
  display,
  selected,
  disabled,
  showCheckbox,
}: {
  icon?: ReactNode
  display: ReactNode
  selected?: boolean
  disabled?: boolean
  /**@description if this is true then we won't show right ✅ and vise versa  */
  showCheckbox?: boolean
}) {
  return (
    <>
      <div className="flex justify-start items-center gap-2 w-full">
        {showCheckbox && (
          <Checkbox id={undefined} checked={selected} disabled={disabled} />
        )}
        {icon &&
          (isString(icon) ? (
            <Svg
              classes="w-4 h-4 text-muted-foreground"
              name={icon}
              strokeWidth={2}
            />
          ) : (
            icon
          ))}

        {display && (
          <span
            className={joinClassNames(
              "block truncate my-auto text-primary w-full",
              selected && "font-semibold"
            )}
          >
            {display}
          </span>
        )}
      </div>
      {selected && !showCheckbox && (
        <div className="my-auto flex justify-end">
          <Svg name="check" classes="w-4 h-4" strokeWidth={2} />
        </div>
      )}
    </>
  )
}
